import React, { useState, useEffect } from "react";
import { Strength, DefaultStrength } from "../models/Strength";
import "./PasswordStrengthIndicator.scss";

function PasswordStrengthIndicator(props) {
  const dicewareLink = props.dicewareLink || null;

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState(DefaultStrength);

  useEffect(() => {
    // We keep track of a cancellation flag to address a race condition
    // with the Strength promise. As Strength is asynchronous the results
    // can be returned in any order. We use a cancellation flag to ensure
    // we only update the state with Strength results if this useEffect
    // has not been cleaned up.
    let cancelled = false;
    let cleanup = () => {
      cancelled = true;
    };

    setLoading(true);
    Strength(password).then(s => {
      if (cancelled) {
        return;
      }
      setStrength(s);
      setLoading(false);
    });

    return cleanup;
  }, [password]);

  let info;
  if (password === "") {
    info = (
      <div>
        <p>Enter a potential password above to estimate its strength.</p>
        <p>{dicewareLink}</p>
      </div>
    );
  } else if (loading) {
    info = <div />;
  } else {
    info = (
      <div>
        <h2>{messages[strength.score]}</h2>
        <p>
          This password would take{" "}
          {strength.crack_times_display.offline_slow_hashing_1e4_per_second} to
          crack.
        </p>

        <div class="feedback">
          {strength.feedback.warnings.length > 0 && (
            <div>
              <h3>Warnings:</h3>
              <ul>
                {strength.feedback.warnings.map((value, index) => {
                  return <li key={index}>{value}</li>;
                })}
              </ul>
            </div>
          )}

          {strength.feedback.suggestions.length > 0 && (
            <div>
              <h3>Suggestions:</h3>
              <ul>
                {strength.feedback.suggestions.map((value, index) => {
                  return <li key={index}>{value}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="password-strength-root" data-score={strength.score}>
      <input
        onChange={e => setPassword(e.target.value)}
        type="password"
        placeholder="🔐 Enter Password"
      />
      <progress max={4} value={strength.score} />
      {info}
    </div>
  );
}

const messages = {
  "": "Type a potential password above to get started",
  0: "🚫 Very easy to guess",
  1: "☹️ Easy to guess",
  2: "🙁 Not great",
  3: "😐 Getting closer",
  4: "👍 Acceptable password"
};

export default PasswordStrengthIndicator;

import React from "react";
import "./DicewarePassphraseGenerator.scss";
import generatePassphrase from "eff-diceware-passphrase";

const DEFAULT_WORD_COUNT = 8;

function DicewarePassphraseGenerator(props) {
  const wordCount = props.wordCount || DEFAULT_WORD_COUNT;
  const phrase = generatePassphrase(wordCount).join(" ");

  return (
    <div className="diceware-generator">
      <code>{phrase}</code>
    </div>
  );
}

export default DicewarePassphraseGenerator;

import zxcvbn from "zxcvbn";
import { pwnedPassword } from "hibp";
import additional_inputs from "./additional_inputs.js";

const DefaultStrength = {
  score: -1,
  calc_time: 1,
  crack_times_display: {
    offline_fast_hashing_1e10_per_second: "less than a second",
    offline_slow_hashing_1e4_per_second: "less than a second",
    online_no_throttling_10_per_second: "less than a second",
    online_throttling_100_per_hour: "2 minutes"
  },
  crack_times_seconds: {
    offline_fast_hashing_1e10_per_second: 6.2e-9,
    offline_slow_hashing_1e4_per_second: 0.0062,
    online_no_throttling_10_per_second: 6.2,
    online_throttling_100_per_hour: 2232
  },
  feedback: {
    suggestions: [],
    warnings: []
  }
};

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal"
});

// Strength uses haveibeenpwned.com and zxcvbn to provide information
// regarding the strength of the provided password.
function Strength(password) {
  // If the password is blank return the default value
  if (password === "") {
    return new Promise(resolve => {
      resolve(DefaultStrength);
    });
  }

  // Check haveibeenpwned.com to see if the password is included in a breach
  let pwned = pwnedPassword(password);

  // Use  to get strength estimate
  let zxcvbnResults = zxcvbn(password, additional_inputs);

  return new Promise((resolve, reject) => {
    pwned
      .then(pwnedCount => {
        let results = merge(zxcvbnResults, pwnedCount);
        resolve(results);
      })
      .catch(err => reject(err));
  });
}

// merge combines the results of checking the password strength with zxcvbn
// and if the password has been exposed in a breach with haveibeenpwned.com
// into a single object with information about the strength.
function merge(zxcvbnResults, pwnedCount) {
  let results = zxcvbnResults;

  // Include pwned Count
  results.pwnedCount = pwnedCount;

  // If pwned override time estimates and score from zxcvbn
  if (pwnedCount > 0) {
    results.score = 0;
    results.calc_time = 1;
    results.crack_times_display = {
      offline_fast_hashing_1e10_per_second: "less than a second",
      offline_slow_hashing_1e4_per_second: "less than a second",
      online_no_throttling_10_per_second: "less than a second",
      online_throttling_100_per_hour: "2 minutes"
    };
    results.crack_times_seconds = {
      offline_fast_hashing_1e10_per_second: 6.2e-9,
      offline_slow_hashing_1e4_per_second: 0.0062,
      online_no_throttling_10_per_second: 6.2,
      online_throttling_100_per_hour: 2232
    };
  }

  // Combine warnings from zxcvbn and haveibeenpwned.com
  results.feedback.warnings = [];
  if (pwnedCount > 0) {
    results.feedback.warnings.push(
      `This password has appeared ${numberFormatter.format(
        pwnedCount
      )} times in data breaches and should never be used. If you've ever used it anywhere before, change it now.`
    );
  }
  if (results.feedback.warning !== "") {
    results.feedback.warnings.push(results.feedback.warning + ".");
  }

  return results;
}

export { Strength, DefaultStrength };

// companies Toolhouse works with
const companies = [
  "gilead",
  "nni",
  "nnit",
  "nordisk",
  "novo",
  "novonordisk",
  "toolhouse",
  "spark",
  "otsuka",
  "sarepta",
  "otsuka",
  "enzyvant",
  "expeditors",
  "genzyme"
];

// brands and products we work with
const brands = [
  "abilify",
  "abilify",
  "ambisome",
  "atripla",
  "biktarvy",
  "breathtek",
  "busulfex",
  "cayston",
  "complera",
  "dacogen",
  "descovy",
  "emtriva",
  "epclusa",
  "exondys",
  "exondys51",
  "fiasp",
  "genvoya",
  "glucagen",
  "glucagon",
  "harvoni",
  "hepsera",
  "hypokit",
  "jynarque",
  "letairis",
  "levemir",
  "levemir",
  "lexiscan",
  "macrilen",
  "macugen",
  "maintena",
  "mycite",
  "norditropin",
  "novoeight",
  "novolin",
  "novolog",
  "novomix",
  "novorapid",
  "novoseven",
  "odefsey",
  "ozempic",
  "ranexa",
  "rebinyn",
  "rexulti",
  "rybelsus",
  "ryzodeg",
  "samsca",
  "saxenda",
  "sovaldi",
  "stribild",
  "tamiflu",
  "tresiba",
  "tretten",
  "truvada",
  "tybost",
  "vagifem",
  "vemlidy",
  "victoza",
  "viread",
  "viread",
  "vosevi",
  "xultophy",
  "yescarta",
  "zydelig"
];

// list of names that are not already included by zxcvbn
const people = [
  "aarti",
  "belka",
  "cantle",
  "dostart",
  "gradin",
  "hegman",
  "nadia",
  "obrist",
  "tellesbo",
  "vaid"
];

export default [...companies, ...brands, ...people];

import React from "react";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import PasswordStrengthIndicator from "./components/PasswordStrengthIndicator";
import DicewarePassphraseGenerator from "./components/DicewarePassphraseGenerator";

function App() {
  const dicewareLink = <Link to="/diceware">Generate a diceware passphrase</Link>;

  return (
    <Router>
      <Switch>
        <Route path="/diceware">
          <DicewarePassphraseGenerator wordCount={6} />
        </Route>
        <Route path="/">
          <PasswordStrengthIndicator dicewareLink={dicewareLink} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
